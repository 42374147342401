import styled from "styled-components"

export const Body = styled.div`
  width: 80%;
  margin: auto;
  display: grid;

  h1 {
    font-size: 1.8em;
    @media (max-width: 630px) {
      text-align: center;
      font-size: 1.3em;
    }
  }

  .p-equal {
    @media (max-width: 630px) {
      text-align: center;
      width: 360px;
    }
  }

  @media (max-width: 1400px) {
    width: 90%;
  }

  @media (max-width: 1310px) {
    width: 95%;
  }
`

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  /* justify-items: left; */

  /* @media (max-width: 1400px) {
    justify-items: left;
  } */

  @media (max-width: 980px) {
    grid-template-columns: 100%;
    justify-items: center;
  }
`
