import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const CardContainer = styled.div`
  #container {
    display: flex;
    /* border: 1px solid black; */
    width: 600px;
    height: 350px;
    margin: 1rem;

    @media (max-width: 1310px) {
      width: 450px;
      margin-bottom: 4rem;
    }
    @media (max-width: 980px) {
      width: 600px;
    }

    @media (max-width: 630px) {
      width: 350px;
    }

    h2 {
      margin-bottom: 0.5rem;
      @media (max-width: 1310px) {
        font-size: 1.3em;
      }
    }

    p {
      @media (max-width: 1310px) {
        font-size: 0.8em;
      }
    }

    a {
      text-decoration: none;
    }

    .img-container {
      padding: 0.5rem;
      margin-top: 2.5rem;
      @media (max-width: 630px) {
        padding: 0.2rem;
        /* margin: 2rem 0; */
      }

      img {
        @media (max-width: 630px) {
          width: 100px;
          margin: 2rem 0;
        }
      }
    }

    .content-container {
      padding: 0.5rem;
    }

    .brochure-link {
      color: #f26522;
      font-size: 0.8em;
      margin-bottom: 0.5rem;
    }
  }

  #container {
    &:hover h2 {
      font-size: 1.8em;
      transition-duration: 0.5s;
    }
  }

  #container {
    &:hover img {
      width: 230px;
      transition-duration: 0.5s;
    }
  }
`

const ViewMoreBtn = styled.button`
  margin: 1rem 0;
  padding: 0.4rem;
  border-radius: 5px;
  background-color: #ccd2c6;

  &:hover {
    cursor: pointer;
  }

  &:hover ~ h2 {
    font-size: 3rem;
  }
`

const ProductInfoCard = props => {
  return (
    <CardContainer>
      <div id="container">
        <div className="img-container">
          <img src={props.image} alt={props.machineAlt} />
        </div>
        <div className="content-container">
          <h2 id="machine-title">{props.machineTitle}</h2>
          <a href={props.brochureLink} target="_blank">
            <div className="brochure-link">{props.brochureDescription}</div>
          </a>
          <p>{props.textContent}</p>

          <Link to={props.machinePageLink}>
            <ViewMoreBtn>{props.btnText}</ViewMoreBtn>
          </Link>
        </div>
      </div>
    </CardContainer>
  )
}

export default ProductInfoCard
