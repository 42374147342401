import React from "react"
import MainLayout from "../components/MainLayout"

import ProductInfoCard from "../components/ProductInfoCard"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"

import { Body, CardContainer } from "../components/styles/ProductPage2Styles"

import PageHeading from "../components/PageHeadings"
import DemoSection from "../components/DemoSection"

import OrbitzImage from "../images/orbitz.png"
import K10CompactImage from "../images/k10-compact-17d-padassist-x200.png"
import K10StandardImage from "../images/K10-STANDARD-20D-TRACTION-w200.png"
import K11Image from "../images/KODIAK-k11-17-D-front-WEB-200.png"
import K16Image from "../images/KODIAK-K16-26-D-front-WEB-200.png"
import K25Image from "../images/KODIAK-K24-32-D-front-WEB-200.png"

const ProductsPage2 = () => {
  return (
    <>
      <SEO title="View Floor Cleaning Machines" />
      <MainLayout>
        <PageHeading
          pageHeading="View Floor Cleaning Machines"
          description="Learn about floor cleaning scrubbers"
        />
        <Body>
          <h1>Find a Machine That Fits Your Solution</h1>
          <p className="p-equal">
            Not all cleaning needs are equal. Discover equipment for your
            business needs.{" "}
          </p>
          <CardContainer>
            <ProductInfoCard
              image={OrbitzImage}
              machineAlt="Orbital Floor Scrubber"
              machineTitle="Floor Machines"
              brochureLink="../ORBITZ-SALES-SHEET.pdf"
              brochureDescription="Download Kodiak's Orbital Scrubber Brochure >"
              textContent="Kodiak Floor Machines deliver premium chemical-free floor stripping while reducing water consumption by up to 70%.  Easily swap out pad types for multiple floor applications to accommodate your unique job.  Choose from three styles ranging from 20 inch and 10 inch options. "
              btnText="Research Floor Machines"
              machinePageLink="/floor-series-page"
            />
            <ProductInfoCard
              image={K10CompactImage}
              machineAlt="K10 Compact Floor Scrubber"
              machineTitle="K10 Compact"
              brochureLink="../k-k10s-k10c-BROCHURE-V20073.pdf"
              brochureDescription="Download Kodiak's K10 Series Brochure >"
              textContent="This small floor scrubber is great for cleaning those hard to reach area.  Available with two different disk types, this compact floor scrubber is nimble enough to clean congested areas with ease. Great for scrubbing Auto Dealers, Fabrication Shops and more."
              btnText="Research K10 Compact Machine"
              machinePageLink="/k10-compact"
            />
            <ProductInfoCard
              image={K10StandardImage}
              machineAlt="K10 Standard Floor Scrubber"
              machineTitle="K10 Standard"
              brochureLink="../ORBITZ-SALES-SHEET.pdf"
              brochureDescription="Download Kodiak's K10 Series Brochure >"
              textContent="The K10 Standard small walk behind floor scrubber is available with disk and orbital scrub deck options.  Its maneuverable design provides versatility on a durable platform.  Wide range of  industrial plant cleaning applications includes, but not limited to warehouses, and food packaging plants.
              "
              btnText="Research K10 Standard Machine"
              machinePageLink="/k10-standard"
            />
            <ProductInfoCard
              image={K11Image}
              machineAlt="K12 Floor Scrubber"
              machineTitle="K12 Floor Scrubber"
              brochureLink="../kodiak-walk-behind-sm.pdf"
              brochureDescription="Download Kodiak's Walk-Behind Scrubber Brochure >"
              textContent="I'm baby vinyl squid retro, kickstarter taiyaki narwhal pork belly knausgaard distillery leggings fixie ramps freegan enamel pin lomo. Fingerstache you probably haven't heard of them ennui kitsch succulents. Quinoa brooklyn ramps, jean shorts hoodie flexitarian subway tile aesthetic umami hashtag. Poke "
              btnText="Research K12 Floor Scrubber Machine"
            />
            <ProductInfoCard
              image={K16Image}
              machineAlt="K16 Floor Scrubber"
              machineTitle="K16 Floor Scrubber"
              brochureLink="../kodiak-walk-behind-sm.pdf"
              brochureDescription="Download Kodiak's Walk-Behind Scrubber Brochure >"
              textContent="I'm baby vinyl squid retro, kickstarter taiyaki narwhal pork belly knausgaard distillery leggings fixie ramps freegan enamel pin lomo. Fingerstache you probably haven't heard of them ennui kitsch succulents. Quinoa brooklyn ramps, jean shorts hoodie flexitarian subway tile aesthetic umami hashtag. Poke "
              btnText="Research K16 Floor Scrubber Machine"
            />
            <ProductInfoCard
              image={K25Image}
              machineAlt="K25 Floor Scrubber"
              machineTitle="K25 Floor Scrubber"
              brochureLink="../kodiak-walk-behind-sm.pdf"
              brochureDescription="Download Kodiak's Walk-Behind Scrubber Brochure >"
              textContent="I'm baby vinyl squid retro, kickstarter taiyaki narwhal pork belly knausgaard distillery leggings fixie ramps freegan enamel pin lomo. Fingerstache you probably haven't heard of them ennui kitsch succulents. Quinoa brooklyn ramps, jean shorts hoodie flexitarian subway tile aesthetic umami hashtag. Poke "
              btnText="Research K25 Floor Scrubber Machine"
            />
          </CardContainer>
        </Body>
      </MainLayout>
    </>
  )
}

export default ProductsPage2
